<template>
    <div class="grid">
        <!--Begin : SideBarRight  TreeView -->
        <div class="col-12 md:col-3 xl:col-3 xl:col-offset-1">
            <div class="p-card">
                <div class="p-card-title">
                    <div class="card-header">
                        <div class="card-title">
                            <h5 class="ml-3 font-semibold text-gray-900">Módulo/Menú</h5>
                        </div>
                    </div>
                </div>
                <div class="flex">
                    <div class="flex-grow-1">
                    </div>
                    <div class="flex-none">
                        <a href="javascript:void(0)" class="p-2 my-2 " v-tooltip.top="'Editar Módulo'"  @click="upsertModulo_onClick(true)" > 
                            <i class="pi pi-pencil"></i>
                        </a>
                        <a href="javascript:void(0)" class="p-2 my-2" v-tooltip.top="'Agregar Módulo'"  @click="upsertModulo_onClick()" > 
                            <i class="pi pi-plus-circle"></i>
                        </a>
                    </div>
                </div>
                <Tree :value="treeModel" @node-select="onNodeSelect" selectionMode="single" v-model:selectionKeys="selectedKey" >
                    <template #default="slotProps"  >
                        <div class="flex">
                            <div class="flex-grow-1 font-bold">{{slotProps.node.label}}</div>
                        </div>
                    </template>
                    <template #url="slotProps">
                        <a class="font-normal" href="javascript:void(0)">{{slotProps.node.label}}</a>
                    </template>
                </Tree>
            </div>
        </div>
        <!--Begin : SideBarRight  TreeView -->

        <!-- Begin: Admin Menu -->
        <div class="col-12 md:col-8 xl:col-6" >
            <div class="p-card">
                <div class="p-card-title">
                    <div class="card-header">
                        <div class="card-title">
                            <h5 class="ml-3 font-semibold text-gray-900">Administración de Menú</h5>
                        </div>
                    </div>
                </div>
                <div class="md:flex block">
                    <div class="flex-grow-1">
                         <Breadcrumb  :home="home" :model="itemBreadcrumb" />
                    </div>
                    <div class="flex-none flex justify-content-end">
                        <a href="javascript:void(0)" class="p-2 my-2" v-tooltip.top="'Agregar Menú'"  @click="upsertMenu_onClick()" > 
                            <i class="pi pi-plus-circle"></i> Menú
                        </a>
                    </div>
                </div>
                <!--Begin: Menu Descriptio -->
                <div class=" hover:bg-blue-50 my-3 border-round" v-if="selectedMenu">
                    <UpsertMenu  :Service="permisosService" :Menu="menu"  v-if="modeUpsertMenu" @save_onEmit="saveMenu_onClick" @cancel_onEmit="cancelMenu_onClick()" /> 
                    <div class="flex flex-column py-3" v-else>
                        <div class="flex align-items-center">
                            <div class="mx-3">
                                <div class="shadow-3  symbol symbol-40 symbol-circle" :class="(!menu.men_estado ? 'symbol-light-danger' : '')">
                                    <span class="symbol-label ">
                                        <i :class="menu.men_icon"/>
                                    </span>
                                </div>
                            </div>
                            <div class="flex-grow-1">
                                <a href="javascript:void(0)" class="text-gray-900 font-semibold  hover:text-primary text-lg">{{menu.men_descripcion}}</a>
                                <span class="text-gray-400 font-semibold font-normal block text-lg"> {{menu.men_url}} </span> 
                            </div>
                        </div>
                        <div class="flex flex-column">
                            <Divider type="dashed" class="p-divider-muted"/>
                            <div class="flex justify-content-end">
                                <Button type="button"  icon="pi pi-pencil text-gray-400" label="Editar" class="p-button-text mx-3"  v-tooltip.top="'Editar'" @click="upsertMenu_onClick(true)" /> 
                            </div>
                        </div>
                        
                    </div>

                </div>
                <!--Begin: Menu Descriptio -->
            </div>
            <!-- Begin: Restricciones -->
            <div class="p-card mt-3" v-if="selectedMenu">
                <div class="flex flex-column " >
                    <Divider align="left" class="bg-white">
                        <div class="flex-inline align-item-center">
                            <i class="pi pi-key mr-2"></i>
                            <b class="mx-2">Restricciones</b>
                        </div>
                    </Divider>
                    <div class="flex justify-content-end">
                        <Button type="button" icon="pi pi-plus-circle" label="Nuevo" class="mx-2 p-button-text" @click="upsertRestriccion_onClick()" />
                    </div>
                    <div class="p-card my-2 shadow-2 hover:bg-blue-50" v-for="restriccion in restriccionList" :key="restriccion.mer_codigo">
                        <UpsertRestriccion :key="restriccion.mer_codigo"  :Service="permisosService" :Restriccion="restriccionMenu" v-if="restriccion.mer_codigo == codeRestriccion"  @cancel_onEmit="cancelRestriccion_onClick()" @save_onEmit="saveRestriccion_onClick($event)"/> 
                        <div class="flex flex-column" v-else>
                            <div class="flex align-items-center">
                                <div class="mx-3">
                                    <div class=" shadow-3 symbol symbol-40 symbol-circle " :class="[ restriccion.mer_estado ? 'symbol-light-success' : 'symbol-light-danger']">
                                        <span class="symbol-label ">
                                            <i :class="'pi ' +  [restriccion.mer_estado ? 'pi-lock' : 'pi-lock-open']"/>
                                        </span>
                                    </div>
                                </div>
                                <div class="flex-grow-1">
                                    <a href="javascript:void(0)" class="text-gray-900  hover:text-primary text-lg">{{restriccion.mer_elemento}}</a>
                                    <span class="text-gray-500 font-normal block text-lg"> {{restriccion.mer_descripcion}}</span> 
                                </div>
                                <div class=" flex-none flex justify-content-end my-2">
                                    <a href="javascript:void(0)" class="btn btn-bg-light p-2 mr-2 text-primary"  v-tooltip.top="'Editar'" @click="upsertRestriccion_onClick(restriccion, true)" > 
                                        <i class="pi pi-pencil"></i>
                                    </a>
                                </div>
                            </div>
                            
                        </div>

                    </div>
                </div>
            </div>
            <!-- End: Restricciones -->
          
        </div>
        <!-- End : Admin Menu -->
    </div>


    <!--Begin: Create Module -->
    <Dialog v-model:visible="isModuloDialog" :modal="true" :style="{width: '460px'}">
        <template #header>
            <h4 class="font-semibold text-gray-900">Módulo</h4>
        </template>
        <div class="p-fluid">
            <div class="field">
                <label for="mod_nombre">Nombre módulo</label>
                <InputText id="mod_nombre" class="text-center" v-model.trim="modulo.mod_nombre" required="true" autofocus :class="{'p-invalid': submittedModulo && !modulo.mod_nombre}"  placeholder="Nombre de módulo" />
                <small class="p-error" v-if="submittedModulo && !modulo.mod_nombre">* Nombre requerido.</small>
            </div>
        </div>
        <template #footer>
            <Button type="button" label="Cancelar" class="mt-2 p-button-danger p-button-text " @click="isModuloDialog =  false" />
            <Button type="submit" label="Guardar" class="mt-2 " @click="saveModulo_onClick()" />
        </template>
    </Dialog>
    <!--End: Create Module -->

</template>

<script>

import PermisosService from '@/service/permisosService'

import UpsertRestriccion from '@/components/Admin/Modulos/UpsertRestriccion.vue'
import UpsertMenu from '@/components/Admin/Modulos/UpsertMenu.vue'

export default {
    permisosService : null,

    data(){
        return{
            modulo : {
                mod_codig : 0,
                mod_nombre :  ""
            },
            moduloList : [],

            restriccionList : [],
            
            menu :{
                men_codigo: 0,
                men_descripcion : "",
                men_icon :  "",
                men_url : "",
                men_padre : 0,
                men_estado : true,
                men_codmod : 0
            },
            menuList : [],
            
            codeRestriccion : -1,
            selectedMenu : false,

            //Tree
            nodeTree :  {},
            treeModel : [],
            selectedKey : null,

            //Breadcrumb
            home: {icon: 'pi pi-home text-primary'},
            itemBreadcrumb : [],

            //Dialogs
            isModuloDialog : false,
            submittedModulo: false,
            modeUpsertMenu :  false
        }
    },
    created(){
        this.permisosService =  new PermisosService(this.axios);

    },
    async mounted(){
        
        await this.init();
    },
    methods: {
        async init(){
            await this.createTreeModel();
        },

        async fxGetRestriccion(men_codigo){
            await this.permisosService.getRestriccionesMenu(men_codigo).then(res => this.restriccionList = res);
        },
        upsertModulo_onClick(editMode =  false){
            try{
                if(!editMode){
                    this.modulo = {
                        mod_codigo : 0,
                        mod_nombre : ""
                    }
                }
                else if(this.modulo.mod_codigo){  
                    this.modulo =  {...this.nodeTree.data };  
                }
                else{      
                    this.$toast.add({severity:'warn', summary: 'No válido', detail: "Seleccione un módulo válido", life: 3000});   
                    return;
                }
                
                this.isModuloDialog =  true;
            }catch(ex){
                this.$toast.add({severity:'error', summary: 'No válido', detail: ex, life: 3000});   
            }
        },
        
        async saveModulo_onClick(){
            try{
                this.submittedModulo = true;
                if(!this.modulo.mod_nombre.trim()){
                    return;
                }

                //-------------------------------------//
                if(this.modulo.mod_codigo == 0) //insert
                {
                    await this.permisosService.createModulo(this.modulo).then(res=> this.modulo  =  res)
                }else{ //Edit

                }
                await this.init();
                this.submittedModulo = true;
                this.isModuloDialog = false;
            }catch(ex){
                this.$toast.add({severity:'error', summary: '¡Error al realizar la operación!', detail:'Msg:' + ex, life: 1500});  
            }
        },


        upsertMenu_onClick(editMode = false){
            if(!this.menu.men_codigo && !this.modulo.mod_codigo){  
                this.$toast.add({severity:'warn', summary: 'No válido', detail: "Seleccione una opción de menú válido", life: 3000});   
                 return;
            }

            if(!editMode){ //New
                this.menu  = {
                    men_codigo: 0,
                    men_descripcion : "",
                    men_icon :  "",
                    men_url : "",
                    men_codmod : this.modulo.mod_codigo,
                    men_padre : this.menu.men_codigo
                }
            }

            //Controles
            this.selectedMenu =  true;
            this.modeUpsertMenu =  true;
        },

        cancelMenu_onClick (){
            this.modeUpsertMenu =  false
            if(this.nodeTree.data.men_codigo){ //Return node selected
                this.menu =  this.nodeTree.data
            }else{
                 this.selectedMenu =  false;
            }
        },
        
        async saveMenu_onClick(event){
            this.menu = event;
            await this.init();
            this.modeUpsertMenu =  false;
        },

        async saveRestriccion_onClick(event){
             this.codeRestriccion = -1;
            await this.fxGetRestriccion(event.mer_codmen)
        },

        //#region Begin: Restriccion 
        cancelRestriccion_onClick(){
            this.codeRestriccion = -1;
            if(this.restriccionMenu.mer_codigo == 0){
                this.restriccionList.splice(0, 1);
            }
        },
        upsertRestriccion_onClick(dataValue =  null,editMode =  false){
            
            if(editMode){
                this.codeRestriccion =  dataValue.mer_codigo
                this.restriccionMenu = {...dataValue}
            }else{
                if(this.codeRestriccion >= 0) return; //Si ya esta seleccionado para agregar
                this.restriccionMenu =  {
                    mer_codigo: 0,
                    mer_descripcion : "",
                    mer_elemento: "",
                    mer_estado: true,
                    mer_codmen: this.menu.men_codigo
                }
                this.codeRestriccion = 0
                this.restriccionList.splice(0,0,this.restriccionMenu)
            }
        },
        //#enregion End: Restriccion

       

        // click onto text
        async onNodeSelect(node){
            this.modulo  = { }
            this.nodeTree =  node
            this.menu = { men_codigo : 0}
           
            if(this.nodeTree.data.men_codigo){ // if is Menu
                this.menu =  this.nodeTree.data;
                this.modulo  = { mod_codigo :  this.menu.men_codmod }
                await this.fxGetRestriccion(this.menu.men_codigo);// Get restriccion
                this.selectedMenu = true;
            }else{
                this.selectedMenu = false;
                this.modulo =  this.nodeTree.data;
            }
            this.itemBreadcrumb =  this.nodeTree.breadcrumb
        },

        async createTreeModel(){
            //Get all modules
            await this.permisosService.getModulo().then(result => this.moduloList = result)

            var menuModel = []; //itemModel to TreeView
            await  Promise.all(this.moduloList.map(async (element,index) => {      
                var breadTemp=  [{
                    label: element.mod_nombre,
                    to: '#'
                }] //Add breadcrumb

                var itemModel = {
                    key: `${index}`,
                    data: element,
                    label: element.mod_nombre,
                    children: await this.createChildren(`${index}`, element.mod_codigo, breadTemp),
                    breadcrumb : breadTemp, //custom property
                }
               
                menuModel.push(itemModel)
            }));

            this.treeModel  = menuModel;
        },
        /**
         * Función recursiva para crear el arbol de menu desde nivel 1 (0)
         * @param {Integer} idx Index nivel del antecesor default 0
         * @param {Integer} codigo_modulo Codigo de Modulo
         * @param {Array} breadcrumb breadcrum del antecesor
         * @param {Integer} menuParentId Parent Id
         * @return {Array} menuChild creado
         */
        async createChildren(idx,codigo_modulo, breadcrumb = [] , menuParentId = 0){
            var menuChild = []; //What is?
            var menuParent = []
            await this.permisosService.getMenu(codigo_modulo,menuParentId).then(res=>   menuParent =  res.data )

            await  Promise.all(menuParent.map(async (element,index) => {
                var breadTemp = [...breadcrumb]
                breadTemp.push({
                    label : element.men_descripcion,
                    to: '#'
                    })
                var key = `${idx}-${index}`
                var node = {
                   key: key,
                   data: element,
                   label: element.men_descripcion,
                   type:"url",
                   children : await this.createChildren(key,codigo_modulo, breadTemp, element.men_codigo),
                   icon : `${element.men_icon} text-${(element.men_estado ? 'primary' : "danger")}`,
                   breadcrumb : breadTemp // custom property
                }
               menuChild.push(node)
           }));

           return menuChild;
       }
    },
    components:{
        UpsertRestriccion,
        UpsertMenu
    }
}
</script>