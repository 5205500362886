<template>
    <form class="p-fluid p-2" >
        <div class="field">
            <label for="men_descripcion">Descripción menú</label>
            <InputText id="men_descripcion" v-model.trim="menu.men_descripcion" required="true" autofocus :class="{'p-invalid': submittedMenu && !menu.men_descripcion}"  placeholder="Descripción menú" />
            <small class="p-error" v-if="submittedMenu && !menu.men_descripcion">* Descripción requerido.</small>
        </div>

        <div class="formgrid grid">
            <div class="col-8 ">
                <div class="field">
                    <label for="men_url">Página/Url</label>
                    <InputText id="men_url" v-model.trim="menu.men_url"   placeholder="URL Router" />
                    <small class="p-error" > URL  Router View Name.</small>
                </div>
            </div>
            <div class="col-4">
                <div class="field">
                    <label for="men_icon" >Icono</label>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                            <i :class="menu.men_icon +' text-primary'"></i>
                        </span>
                        <InputText for="men_icon"  v-model="menu.men_icon" />
                    </div>
                </div>
            </div>
        </div>

        <div class="flex-none flex align-items-center justify-content-end">
            <div class="field-checkbox">
                <InputSwitch  id="mer_estado" v-model="menu.men_estado" :binary="true"/> 
                <label>Activo</label> 
            </div>
        </div>
        
        <div class="flex justify-content">
            <Button type="button" label="Cancelar" class="p-button-text p-button-danger mx-1 " @click="cancel_onClick()"/>
            <Button type="button" label="Guardar" class="mx-2" @click="saveMenu_onClick()" />
        </div>
    </form>
</template>


<script>
export default {
    emits: ["save_onEmit", "cancel_onEmit"],
    props:{
        Menu :{
            type: Object,
            default(){
                return {}
            }
        },
        Service: null,
    },

    data(){
        return{
            menu : {...this.Menu},
            submittedMenu : false,
        }
    },
    methods:{
        cancel_onClick(){
            this.$emit("cancel_onEmit")
        },



        async saveMenu_onClick(){
            this.submittedMenu  =  true

            if(!this.menu.men_descripcion || !this.menu.men_descripcion.trim()){
                return
            }

            this.$confirm.require({
                message: `¿Está seguro de guardar  este registro: ${this.menu.men_descripcion}?`,
                header: 'Guardar Menu',
                icon: 'pi pi-info-circle',
                rejectClass: "p-button-danger p-button-text",
                acceptClass: 'p-button-info',
                acceptLabel: "Si, guardar.",
                accept: async () => {
                    try{
                        await this.Service.upsertMenu(this.menu).then(res => this.menu =  res);
                        this.$emit("save_onEmit",this.menu)
                    }
                    catch(ex){
                        this.$toast.add({severity:'error', summary: '¡Error al realizar la operación!', detail:'Msg:' + ex, life: 1500});  
                    }
                },
            });
        },
    }
}
</script>