<template>
    <form autocomplete="false" class="p-fluid">
        <div class="flex">
            <div class="flex flex-grow-1">
                <div class="p-fluid">
                    <div class="formgrid grid">
                        <label for="firstname" class="col-12 md:col-2">Elemento </label>
                        <div class="col-12 md:col-10">
                            <InputText id="mer_descripcion" type="text" v-model="restriccionMenu.mer_elemento" />
                            <small class="text-primary">* Especifique sección, control o llave para hacer referencia a esta restricción.</small>
                        </div>
                    </div>
                    <div class="formgrid grid mt-2">
                        <label for="lastname" class="col-12 md:col-2">Descripción</label>
                        <div class="col-12 md:col-10">
                            <InputText id="mer_elemento" type="text" v-model="restriccionMenu.mer_descripcion" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex-none flex align-items-center justify-content-center">
                <div class="field-checkbox">
                    <InputSwitch  id="mer_estado" v-model="restriccionMenu.mer_estado" :binary="true"/> 
                    <label>Activo</label> 
                </div>
            </div>
        </div>
        <div class="flex justify-content-end my-2">
            <div class="flex-initial mx-1"><Button label="Cancelar" icon="pi pi-times"  class="p-button-danger p-button-text" @click="cancel_onClick()"/></div>
            <div class="flex-initial mx-1"><Button label="Guardar" icon="pi pi-check" class="" @click="saveRestriccion_onClick()" /></div>
        </div>
    </form>
</template>

<script>
export default {
    
    emits: ["cancel_onEmit", 'save_onEmit'],
    props:{
        Restriccion : {
            type: Object,
            default(){
                return {}
            }
        },
        Service : null
    },
    data(){
        return{
            restriccionMenu :  {...this.Restriccion},

            submitted : false
        }
    },

    methods:{
        cancel_onClick(){
            this.$emit('cancel_onEmit', 'save_onEmit')
        },
        async saveRestriccion_onClick(){
            this.submitted  =  true
            

            if(!this.restriccionMenu.mer_elemento.trim() || !this.restriccionMenu.mer_descripcion.trim()){
                return
            }
            
            var tituloMensaje =  this.restriccionMenu.mer_codigo == 0 ?  "Guardar" : "Actualizar"
            this.$confirm.require({
                message: `¿Está seguro de guardar este registro: ${this.restriccionMenu.mer_descripcion}?`,
                header: `${tituloMensaje} Restricción`,
                icon: 'pi pi-info-circle',
                rejectClass: "p-button-danger p-button-text",
                acceptClass: 'p-button-info',
                accept: async () => {
                    try{
                        this.restriccionMenu["mer_activo"] = this.restriccionMenu.mer_estado;
                        if(this.restriccionMenu.mer_codigo == 0)
                            await this.Service.createRestriccion(this.restriccionMenu).then(res => this.restriccionMenu =  res);
                        else    
                            await this.Service.updateRestriccion(this.restriccionMenu).then(res => this.restriccionMenu =  res);
                        this.$emit("save_onEmit",this.restriccionMenu )
                    }
                    catch(ex){
                        this.$toast.add({severity:'error', summary: '¡Error al realizar la operación!', detail:'Msg:' + ex, life: 1500});  
                    }
                },
            });
        }
    }
}
</script>